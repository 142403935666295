import { TbHeart, TbMusic, TbSearch, TbSettings } from 'solid-icons/tb'
import type { JSX } from 'solid-js'

import { t } from '#/i18n/I18nProvider'

import { NavBar } from './NavBar'
import { NavBarLink } from './NavBarLink'

export const AppNavBar = (props: JSX.IntrinsicElements['nav']) => {
  return (
    <NavBar {...props}>
      <NavBarLink
        icon={TbMusic}
        href="/"
      >
        {t('navbar.home')}
      </NavBarLink>
      <NavBarLink
        icon={TbSearch}
        href="/search"
      >
        {t('navbar.search')}
      </NavBarLink>
      <NavBarLink
        icon={TbHeart}
        href="/mysongs"
      >
        {t('navbar.mySongs')}
      </NavBarLink>
      <NavBarLink
        icon={TbSettings}
        href="/settings"
      >
        {t('navbar.settings')}
      </NavBarLink>
    </NavBar>
  )
}
