import { createEffect, createMemo, type JSX } from 'solid-js'
import { usePageContext } from 'vike-solid/usePageContext'

import { AppNavBar } from '#/components/NavBar/AppNavBar'
import { Flex } from '#/components/ui/Flex'
import { ProgressiveBlur } from '#/components/ui/ProgressiveBlur'
import { useOrientationLock } from '#/utils/screen'
import { useOverscrollBehavior } from '#/utils/useOverscrollBehavior'

import styles from './AppLayout.module.css'

export default function AppLayout(props: { children?: JSX.Element }) {
  useOrientationLock('portrait-primary')
  useOverscrollBehavior('contain')

  const safeAreaPadding = createMemo(
    () =>
      (usePageContext().config as { safeAreaPadding?: boolean })
        .safeAreaPadding,
  )

  createEffect(() => {
    console.log({ ...usePageContext().config })
  })

  return (
    <Flex
      class={styles.AppLayout}
      flex={1}
      direction="column"
      safeAreaPadding={safeAreaPadding()}
    >
      {props.children}
      <ProgressiveBlur direction="up" />
      <ProgressiveBlur
        direction="down"
        class={styles.ProgressiveBlurDown}
      />
      <AppNavBar class={styles.NavBar} />
    </Flex>
  )
}
