import type { JSX } from 'solid-js'
import { For, mergeProps } from 'solid-js'
import { splitProps } from 'solid-js'

import styles from './ProgressiveBlur.module.css'
export const ProgressiveBlur = (
  props: {
    direction?: 'down' | 'up'
    amount?: number
    numberOfFilters?: number
    algorithm?: 'linear' | 'exponential'
  } & JSX.IntrinsicElements['div'],
) => {
  const [ownProps, rest] = splitProps(props, [
    'direction',
    'amount',
    'numberOfFilters',
    'algorithm',
  ])
  const propsWithDefaults = mergeProps(
    {
      direction: 'down',
      amount: 2,
      numberOfFilters: 8,
      algorithm: 'exponential',
    },
    ownProps,
  )

  const stepExp = (i: number) => {
    const exp = 2 ** i
    return exp * (100 / 2 ** propsWithDefaults.numberOfFilters)
  }

  const stepLinear = (i: number) => {
    return i * (100 / propsWithDefaults.numberOfFilters)
  }

  const getStyle = (i: number) => {
    const step =
      propsWithDefaults.algorithm === 'exponential' ? stepExp : stepLinear

    if (propsWithDefaults.direction === 'up') {
      return {
        'backdrop-filter': `blur(${propsWithDefaults.amount ** (propsWithDefaults.numberOfFilters - i)}px)`,
        mask: `linear-gradient(rgba(0, 0, 0, 1) ${step(i)}%, rgba(0, 0, 0, 0) ${step(i + 1)}%)`,
      }
    } else {
      return {
        'backdrop-filter': `blur(${propsWithDefaults.amount ** i}px)`,
        mask: `linear-gradient(rgba(0, 0, 0, 0) ${step(i)}%, rgba(0, 0, 0, 1) ${step(i + 1)}%)`,
      }
    }
  }

  return (
    <div
      {...rest}
      classList={{
        [styles.ProgressiveBlurContainer!]: true,
        ...rest.classList,
      }}
      data-up={props.direction === 'up' || null}
    >
      <For
        each={Array.from({ length: propsWithDefaults.numberOfFilters }).map(
          (_, i) => i,
        )}
      >
        {(i) => (
          <div
            class={styles.BlurFilter}
            style={getStyle(i)}
          />
        )}
      </For>
    </div>
  )
}
