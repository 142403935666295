import { type Accessor, createEffect, createMemo, createSignal } from 'solid-js'

type Mutation<T> = (prev: T) => T

export function createSignalWithMutations<T>(
  accessor: Accessor<T>,
  { resetOnChange = true }: { resetOnChange?: boolean } = {},
) {
  const [mutations, setMutations] = createSignal<
    { id: string; mutate: Mutation<T>; priority: number }[]
  >([])

  const mutate = (mutate: Mutation<T>, priority = 0) => {
    const id = Math.random().toString()
    const mutation = { id, mutate, priority }

    setMutations((prev) => {
      const newMutations = [...prev, mutation]

      newMutations.sort((a, b) => a.priority - b.priority)

      return newMutations
    })

    return id
  }

  const rollbackMutation = (id: string) => {
    setMutations((prev) => prev.filter((mutation) => mutation.id !== id))
  }

  const value = createMemo(() =>
    mutations().reduce((acc, { mutate }) => mutate(acc), accessor()),
  )

  const reset = () => setMutations([])

  const hasChanges = createMemo(() => mutations().length > 0)

  let isInitial = true

  createEffect(() => {
    if (resetOnChange && (accessor() || true) && !isInitial) {
      reset()
    }

    if (isInitial) {
      isInitial = false
    }
  })

  return [value, { mutate, rollbackMutation, hasChanges, reset }] as const
}

export function createListWithMutations<T>(
  accessor: Accessor<T[]>,
  { resetOnChange = false }: { resetOnChange?: boolean } = {},
) {
  const [list, { mutate: mutateList, rollbackMutation, hasChanges, reset }] =
    createSignalWithMutations(accessor, { resetOnChange })

  const mutateItem = (
    predicate: (item: T) => boolean,
    mutation: Mutation<T>,
    priority = 0,
  ) => {
    return mutateList((prev) => {
      const newList = [...prev]

      newList.forEach((item, index) => {
        if (predicate(item)) {
          newList[index] = mutation(item)
        }
      })

      return newList
    }, priority)
  }

  return [
    list,
    { mutateList, mutateItem, rollbackMutation, hasChanges, reset },
  ] as const
}
